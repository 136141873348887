/* eslint-disable no-use-before-define */

export default Object.freeze({
  registrationSuccess() {
    sendAnalyticsYandex('registration_success');
    sendAnalyticsGoogle('registration_success_web');
  },
  emailVerification() {
    sendAnalyticsYandex('Email_verification');
    sendAnalyticsGoogle('Email_verification_web');
  },
  KYC18yo() {
    sendAnalyticsYandex('KYC_18yo');
  },
  KYCIdentityDocument() {
    sendAnalyticsYandex('KYC_IDENTITY_DOCUMENT');
  },
  KYCFinal() {
    sendAnalyticsYandex('KYC_Final');
    sendAnalyticsGoogle('KYC_Final_web');
  },
  completeSignUp() {
    sendAnalyticsTwitter('tw-omnzc-omrt5');
  }
});

function sendAnalyticsYandex(action) {
  if (!process.env.VUE_APP_YM_ID || typeof window.ym === 'undefined') {
    console.error('sendAnalyticsYandex');
    return;
  }

  console.log('sendAnalyticsYandex', action);

  window.ym(process.env.VUE_APP_YM_ID, 'reachGoal', action);
}

function sendAnalyticsGoogle(action) {
  if (!process.env.VUE_APP_GTM_ID || typeof window.dataLayer === 'undefined') {
    console.error('sendAnalyticsGoogle');
    return;
  }

  console.log('sendAnalyticsGoogle', action);

  window.dataLayer.push({
    event: action
  });
}

function sendAnalyticsTwitter(action) {
  if (typeof window.twq === 'undefined') {
    console.error('sendAnalyticsTwitter');
    return;
  }

  console.log('sendAnalyticsTwitter', action);

  window.twq('event', action, {
    value: null, // use this to pass the value of the conversion (e.g. 5.00)
    currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
    status: null, // use this to pass the status of a sign up or subscription, (e.g. 'started' or 'completed')
    conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
    email_address: null, // use this to pass a user’s email address
    phone_number: null // phone number in E164 standard
  });
}
