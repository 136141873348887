<template lang="pug">
  .confirm_email
    .confirm_email_info
      h2 {{ $t('emailConfirmation.title') }}
      p {{ $t('emailConfirmation.description', { email: emailMasked }) }}
    v-form(ref="form", v-model="valid", @submit.prevent="")
      .input_block
        div {{ $t('formFields.activationCode') }}
        v-text-field(
          :label="$t('formFields.activationCode')",
          v-model.trim="code",
          :rules="[rules.required]")
      .errors(v-if="errors.length")
        .errors__item(v-for="error in errors") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
      v-btn.confirm_email-button(:disabled="!valid || loading", @click="submitForm", type="submit") {{ $t('actions.proceed') }}
      v-btn.confirm_email-button(:disabled="countdownActive", @click.stop="resendConfirmationEmail") {{ $t('actions.resend') }}
        .countdown(v-if="countdownActive")
          Countdown(
            :timer="fiveMinInMs",
            :timerStart="confirmationSentTimestamp",
            :digitsOnly="true",
            @expired="countdownActive = false")
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/Countdown';
import sendAnalytics from '@/analytics';

export default {
  components: { Countdown },
  data() {
    return {
      valid: false,
      loading: false,
      code: '',
      errors: [],
      countdownActive: false,
      fiveMinInMs: 5 * 60 * 1000
    };
  },
  computed: {
    ...mapState('user', [
      'email',
      'confirmationSentTimestamp'
    ]),
    rules() {
      return {
        required: v => !!v || this.$t('validation.required')
      };
    },
    emailMasked() {
      if (!this.email && !/.{2,}@.+.[a-z]{2,}/.test(this.email)) return '';
      const email = this.email;
      const emailIndex = email.indexOf('@');
      const name = email.slice(0, emailIndex);

      if (name.length <= 2) {
        return `${'*'.repeat(name.length)}${email.slice(emailIndex)}`;
      }

      return `${email.slice(0, 1)}${'*'.repeat(emailIndex - 2)}${email[emailIndex - 1]}${email.slice(emailIndex)}`;
    }
  },
  watch: {
    confirmationSentTimestamp(val) {
      if (val) this.countdownActive = true;
    }
  },
  methods: {
    resendConfirmationEmail() {
      if (this.countdownActive) return;

      this.$store.dispatch('user/resendConfirmationEmail');
    },
    async submitForm() {
      if (!this.$refs.form.validate() || this.loading) return;

      this.loading = true;
      try {
        const data = await this.$store.dispatch('user/activateAccount', { confirmKey: this.code });
        if (data.group && data.access_token && data.refresh_token) {
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);
          localStorage.setItem('session_id', data.group.id);
        }

        sendAnalytics.emailVerification();
        this.$router.push({ name: 'complete-signup', replace: true });
      } catch (errors) {
        this.errors = errors;
      } finally {
        this.loading = false;
      }
    }
  },
  async beforeMount() {
    if (localStorage.getItem('access_token')) {
      await this.$store.dispatch('user/getUser');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .confirm_email {
    max-width: 536px;
    width: 100%;
    margin: 0 auto 40px;
    @media screen and (max-width: 530px) {
      padding: 0 20px;
    }
    &_info {
      margin-left: 25px;

      h2 {
        margin-top: 20vh;
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        color: #1F2126;
        margin-bottom: 2px;
        @media screen and (max-width: 530px) {
          margin: 20px 0 10px;
          text-align: left;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #1F2126;
        @media screen and (max-width: 530px) {
          font-size: 12px;
          line-height: 20px;
          text-align: left;
          margin-bottom: 20px;
        }
      }
    }

    .input_block {
      position: relative;
      >div{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgba(31, 33, 38, 0.6);
        text-align: left;
      }
      ::v-deep .v-input { font-weight: 600; }

      .resend_link {
        position: absolute;
        right: 10px;
        top: 30px;
        font-weight: 600;
        color: $grey-light14;

        &:not(.disabled) > span,
        &:not(.disabled) > i {
          cursor: pointer;
        }

        .v-icon {
          color: $grey-light14;
          margin-right: 5px;
        }

      }
    }

    .v-btn {
      width: 100%;
    }
    &-button {
      height: 40px !important;
      background-color: #0071E3!important;
      border-radius: 5px;
      &:last-child {margin-top: 10px; }
      .countdown {
        margin-left: 20px;
        strong { font-weight: 400; }
      }
    }
    .errors { margin-bottom: 10px; }
  }

  @media screen and (max-width: 650px) {
    .confirm_email_info { margin-left: 0 !important; }

    .resend_link > span { display: none; }
  }

</style>
